import { useContext, useState } from "react";
import UserContext from "../../store/user-context";
import { UpdateMenuModalInput } from "../../@types/lunchs";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup, TextField } from "@material-ui/core";

const serverURI = process.env.REACT_APP_API_ENDPOINT;

function UpdateMenuModal({ open, handleClose, menu, updater }: UpdateMenuModalInput) {

  const userCtx = useContext(UserContext);

  const [description, setDescription] = useState<string>(menu.description);
  const [date, setDate] = useState<string>(menu.date);
  const [isVegetarian, setIsVegetarian] = useState<boolean>(menu.vegetarian);
  const [isVegan, setIsVegan] = useState<boolean>(menu.vegan);
  const [isCeliac, setIsCeliac] = useState<boolean>(menu.celiacFriendly);
  const [isSugar, setIsSugar] = useState<boolean>(menu.sugarFree);
  const [isLactose, setIsLactose] = useState<boolean>(menu.lactoseFree);
  const [isPackaged, setIsPackaged] = useState<boolean>(menu.packaged);

  function handleUpdate() {
    fetch(`${serverURI}/api/menu/${menu.id}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFTOKEN": userCtx.csrf,
      },
      body: JSON.stringify({
        description: description,
        date: date,
        vegetarian: isVegetarian,
        vegan: isVegan,
        celiacFriendly: isCeliac,
        sugarFree: isSugar,
        lactoseFree: isLactose,
        packaged: isPackaged
      }),
    })
      .then((response) => {
        if (!response.ok) userCtx.setLoggedIn(false);
        else {
          updater({
            id: menu.id,
            description: description,
            date: date,
            vegetarian: isVegetarian,
            vegan: isVegan,
            celiacFriendly: isCeliac,
            sugarFree: isSugar,
            lactoseFree: isLactose,
            packaged: isPackaged
          });
          handleClose();
        }
      })
      .catch((err) => console.log(err));
  }

  if (!open) return null;

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Update menu</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Modify the desired fields and press "Update" to save changes.
          </DialogContentText>
          <TextField
            id="date"
            label="Menu day"
            type="date"
            defaultValue={date}
            onChange={(event) => setDate(event.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Menu description"
            type="description"
            defaultValue={description}
            onChange={(event) => setDescription(event.target.value)}
            fullWidth
          />
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={isVegetarian} onChange={() => setIsVegetarian(!isVegetarian)} name="vegetarian" />}
              label="Is it vegetarian?" />
            <FormControlLabel
              control={<Checkbox checked={isVegan} onChange={() => setIsVegan(!isVegan)} name="vegan" />}
              label="Is it vegan?" />
            <FormControlLabel
              control={<Checkbox checked={isCeliac} onChange={() => setIsCeliac(!isCeliac)} name="celiacFriendly" />}
              label="Is it celiac friendly?" />
            <FormControlLabel
              control={<Checkbox checked={isLactose} onChange={() => setIsLactose(!isLactose)} name="lactoseFree" />}
              label="Is lactose free?" />
            <FormControlLabel
              control={<Checkbox checked={isSugar} onChange={() => setIsSugar(!isSugar)} name="sugarFree" />}
              label="Is sugar free?" />
            <FormControlLabel
              control={<Checkbox checked={isPackaged} onChange={() => setIsPackaged(!isPackaged)} name="packaged" />}
              label="Is it packaged?" />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdate} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
  );
}

export default UpdateMenuModal;
