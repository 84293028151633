import { useEffect } from 'react';

function GoogleLogin() {
  const serverURI = process.env.REACT_APP_API_ENDPOINT;
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  useEffect(() => {
    const externalScript = document.createElement("script");
    externalScript.src = "https://accounts.google.com/gsi/client";
    externalScript.id = "external";
    externalScript.async = true;
    externalScript.defer = true;
    externalScript.type = "text/javascript";
    document.body.appendChild(externalScript);
  }, []);

  return (
    <div>
      <div
        id="g_id_onload"
        data-client_id={googleClientId}
        data-context="signin"
        data-login_uri={serverURI + "/api/login"}
        data-auto_prompt="false"
      />
      <div
        className="g_id_signin"
        data-type="standard"
        data-shape="pill"
        data-theme="filled_blue"
        data-text="signin_with"
        data-size="large"
        data-logo_alignment="left"
      />
      <br/>
    </div>
  );
}

export default GoogleLogin;
