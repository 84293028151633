import classes from './MenuList.module.css'
import Menu from './Menu';
import { MenuListType } from '../../@types/lunchs';

function MenuList({menus, updater, deleter}: MenuListType) {
  return (
    <ul className={classes.menuList}>
      {menus?.map(
        (m) => (
          <Menu
            key={m.id}
            menu={m}
            updater={updater}
            deleter={deleter}
          />
        )
      )}
    </ul>
  );
}

export default MenuList;
