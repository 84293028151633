// import { useEffect, useState } from "react";
import GoogleLogin from "./GoogleLogin";
import classes from "./LoginFrame.module.css";
import { useSearchParams } from "react-router-dom";

function LoginFrame() {

  let [params, ]= useSearchParams();
  
  const redirected = params.get('failed');

  return ( 
    <div>
      <>
      {redirected==='1' && (
        <>
        <div className={classes.frame}>
          <header className={classes.header} >
            <img src="mvd-labs-logo.png" className={classes.logo} alt="logo" />
            <p>Remeber to sign in with your @montevideolabs email!</p>
            <GoogleLogin />
          </header>
        </div>
        </>
      )}

      {redirected!=='1' && (
        <div className={classes.frame}>
          <header className={classes.header}>
            <img src="mvd-labs-logo.png" className={classes.logo} alt="logo" />
            <p>Sign in and enjoy your office lunches!</p>
            <GoogleLogin />
          </header>
        </div>
      )}
      </>
    </div>
  );
}

export default LoginFrame;
