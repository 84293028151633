import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import UserContext from "../../store/user-context";
import classes from "./MainNavigation.module.css";

const serverURI = process.env.REACT_APP_API_ENDPOINT;

function MainNavigation() {

  const userCtx = useContext(UserContext);
  const pathname = useLocation().pathname;

  function logout(){
    fetch(`${serverURI}/api/logout`, {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRFTOKEN": userCtx.csrf,
      }
      
    })
      .then((response: Response) => {
        if (response.ok){
          userCtx.setLoggedIn(false);
          userCtx.setCsrfToken("");
          userCtx.setUserName("");
          userCtx.setAdmin(false);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  return (
    <header className={classes.header}>
      <div className={classes.logoDiv}>
        <img src="mvd-labs-logo-hor.png" className={classes.logo} alt="logo-hor"/>
      </div>
      <nav className={classes.nav}>
        <ul>
          {userCtx.adminUser && ((pathname === "/new-menu") ? 
            <li>
              <Link to="/">Weekly choices</Link>
            </li>
            :
            <li>
              <Link to="/new-menu">Add new menu</Link>
            </li>
            )
          }
          <li>
            <button type="button" className={classes.btn} onClick={logout}>Logout</button>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default MainNavigation;
