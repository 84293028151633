import Layout from "./components/layout/Layout";
import { Route, Routes } from "react-router-dom";
import WeeklyChoicePage from "./pages/WeeklyChoicePage";
import RegisterMenuPage from "./pages/RegisterMenuPage";
import LoginPage from "./pages/LoginPage";
import { useContext, useEffect } from "react";
import UserContext from "./store/user-context";
import Cookie from "universal-cookie";
import { UserType } from "./@types/lunchs";

const serverURI = process.env.REACT_APP_API_ENDPOINT;

function LunchsApp() {
  const userCtx = useContext(UserContext);

  useEffect(() => {
    var cookies = new Cookie();
    fetch(`${serverURI}/api/session`, {
      method: "GET",
      credentials: "include",
    })
      .then((response: Response) => response.json())
      .then(({admin, firstName, lastName}: UserType) => {
        userCtx.setLoggedIn(true);
        userCtx.setCsrfToken(cookies.get("csrftoken"));
        userCtx.setUserName(firstName.concat(" ", lastName));
        userCtx.setAdmin(admin);
      })
      .catch((err) => {
        console.log(err.message);
      });
  });

  if (!userCtx.loggedIn) {
    return <LoginPage />;
  }

  return (
      <Layout>
        <Routes>
          <Route path="/" element={<WeeklyChoicePage />} />
          <Route path="/new-menu" element={<RegisterMenuPage />} />
        </Routes>
      </Layout>
  );
}

export default LunchsApp;
