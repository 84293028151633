import { useContext } from "react";
import UserContext from "../../store/user-context";
import { DeleteMenuType } from "../../@types/lunchs";
import { IconButton, Tooltip } from "@material-ui/core";
import { Delete } from "@material-ui/icons";

const serverURI = process.env.REACT_APP_API_ENDPOINT;

function DeleteMenu({ id, visible, deleter }: DeleteMenuType) {
  const userCtx = useContext(UserContext);

  function clickHandler() {
    fetch(`${serverURI}/api/menu/${id}`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "X-CSRFTOKEN": userCtx.csrf,
      },
    })
      .then((response: Response) => {
        if (!response.ok) {
          userCtx.setLoggedIn(false);
        } else {
          visible(false);
        }
        return response.json();
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  return (
    <Tooltip title="Delete menu" arrow>
      <IconButton
        aria-label="delete"
        size="medium"
        onClick={() => {
          clickHandler();
          deleter(id);
        }}
      >
        <Delete />
      </IconButton>
    </Tooltip>
  );
}

export default DeleteMenu;
