import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import LunchsApp from "./LunchsApp";
import { UserContextProvider } from "./store/user-context";

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <UserContextProvider>
    <BrowserRouter>
      <LunchsApp />
    </BrowserRouter>
  </UserContextProvider>
);
