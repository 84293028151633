import { createContext, useState, PropsWithChildren } from "react";
import { ContextType } from "../@types/lunchs"; 

const UserContext = createContext<ContextType>({
  loggedIn: false,
  csrf: "",
  userName: "",
  adminUser: false,
  setLoggedIn: () => {},
  setCsrfToken: () => {},
  setUserName: () => {},
  setAdmin: () => {}
});

export function UserContextProvider({children}: PropsWithChildren<{}>) {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [csrfToken, setCsrfToken] = useState<string>("");
  const [loggedUserName, setLoggedUserName] = useState<string>("");
  const [isAdminUser, setIsAdminUser] = useState<boolean>(false);

  function setLogInHandler(logIn: boolean) {
    setIsLoggedIn(logIn);
  }

  function setCsrfTokenHandler(csrfToken: string) {
    setCsrfToken(csrfToken);
  }

  function setLoggedUserNameHandler(name: string) {
    setLoggedUserName(name);
  }

  function setIsAdminUserHandler(isAdmin: boolean) {
    setIsAdminUser(isAdmin);
  }

  const context: ContextType = {
    loggedIn: isLoggedIn,
    csrf: csrfToken,
    userName: loggedUserName,
    adminUser: isAdminUser,
    setLoggedIn: setLogInHandler,
    setCsrfToken: setCsrfTokenHandler,
    setUserName: setLoggedUserNameHandler,
    setAdmin: setIsAdminUserHandler
  };

  return (
    <UserContext.Provider value={context}>
      {children}
    </UserContext.Provider>
  );
}

export default UserContext;
