import { useState } from "react";
import { ClickAwayListener, Tooltip as MuiTooltip, Fade, createTheme, MuiThemeProvider, alpha, IconButton } from "@material-ui/core";
import { useContext } from "react";
import UserContext from '../../store/user-context';
import classes from './Tooltip.module.css';
import { UserType } from "../../@types/lunchs";
import { CheckCircle } from "@material-ui/icons";

const serverURI = process.env.REACT_APP_API_ENDPOINT;

function Tooltip(props: {id: number}) {
    const userCtx = useContext(UserContext);
    const [users, setUsers] = useState<UserType[]>([]);
    const [open, setOpen] = useState<boolean>(false);

    const handleTooltipClose = () => {
      setOpen(false);
    };
  
    const handleTooltipOpen = () => {
      fetch(`${serverURI}/api/choices/users?menu=${props.id}`, {
            method: "GET",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              "X-CSRFTOKEN": userCtx.csrf,
            },
          })
            .then((response: Response) => response.json())
            .then((data: [UserType]) => setUsers(data))
            .catch((err) => console.log(err));
      setOpen(true);
    };

    const theme = createTheme({
      overrides: {
        MuiTooltip: {
          tooltip: {
            fontSize: "0.75em",
            color: "#fff",
            backgroundColor: alpha("#337AB7", 0.9),
            border: "1px solid transparent",
            borderRadius: "3px",
            padding: "8px 21px",
            fontFamily: "Open Sans,sans-serif"
          }
        }
      }
    });

  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.backDiv} >
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <MuiTooltip 
            title={users.length > 0 ?
              <div className={classes.totalTip}>
                  <b>Subscribed: {users.length}</b>
                  {users.map((i) => <p key={i.mail}><>{i.firstName + " " + i.lastName}<br /></></p>)} 
                </div>
                :
                <b>No one subscribed yet</b>
              }
            placement="right-start" 
            onClose={handleTooltipClose}
            open={open}
            TransitionComponent={Fade}
            TransitionProps={{timeout: 250}}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            interactive={true}
            arrow
            >
              <IconButton onClick={handleTooltipOpen} aria-label="Done" size="medium" style={{ color: "green" }}>
                <CheckCircle/>
              </IconButton>
            </MuiTooltip>
        </ClickAwayListener>
      </div>
    </MuiThemeProvider>
  );
}

export default Tooltip;