import classes from "./Menu.module.css";
import Tooltip from "../days/Tooltip";
import { Tooltip as MuiTooltip } from "@material-ui/core";
import { MenuType, TooltipFactoryType } from "../../@types/lunchs";
import DeleteMenu from "./DeleteMenu";
import { useContext, useState } from "react";
import UserContext from "../../store/user-context";
import {
  createTheme,
  MuiThemeProvider,
  Tooltip as ImageTooltip,
} from "@material-ui/core";
import { RestaurantRounded } from "@material-ui/icons";
import UpdateMenu from "./UpdateMenu";

function Menu({menu, updater, deleter}: {menu: MenuType, updater: (menu: MenuType) => void, deleter: (menuId:number) => void}) {
  const [visible, setVisible] = useState<boolean>(true);

  const userCtx = useContext(UserContext);
  const defaultTheme = createTheme();
  const [clicked, setClicked] = useState(false);
  const [isHover, setIsHover] = useState(false);

  function TooltipFactory({title, source}: TooltipFactoryType) {
    return (
      <ImageTooltip
        arrow
        disableFocusListener
        disableTouchListener
        title={title}
      >
        <img src={source} alt={title} />
      </ImageTooltip>
    );
  }

  function IconsFactory() {
    const icons = [];
    if (menu.vegetarian) icons.push(<TooltipFactory key={menu.id.toLocaleString() + "1"} title="Vegetarian" source="/no-meat.png" />);
    if (menu.vegan) icons.push(<TooltipFactory key={menu.id.toLocaleString() + "2"} title="Vegan" source="/vegan.png" />);
    if (menu.celiacFriendly) icons.push(<TooltipFactory key={menu.id.toLocaleString() + "3"} title="Gluten free" source="/gluten-free.png" />);
    if (menu.sugarFree) icons.push(<TooltipFactory key={menu.id.toLocaleString() + "4"} title="Sugar free" source="/sugar-free.png" />);
    if (menu.lactoseFree) icons.push(<TooltipFactory key={menu.id.toLocaleString() + "5"} title="Lactose free" source="/lactose-free.png" />);
    if (menu.packaged) icons.push(<TooltipFactory key={menu.id.toLocaleString() + "6"} title="Packaged" source="/food-package.png" />);

    return <>{icons}</>;
  }

  return (
    <MuiThemeProvider theme={defaultTheme}>
      {visible && (
        <li key={menu.id} className={classes.menu}>
          <div className={classes.outerFlex}>
            <div className={classes.menuFlex}>
              <span>
                <RestaurantRounded style={{ color: "darkslategray", border: "1px solid darkslategray", borderRadius: "50%", }}/>
                <span className={classes.span}>&nbsp;{menu.description}</span>
                <span><IconsFactory /></span>              
              </span>
            </div>
            <div className={classes.innerFlex}>
              <MuiTooltip title="Click to see who subscribed" open={!clicked && isHover} arrow>
                <div onClick={() => setClicked(!clicked)}
                    onMouseOver={() => {setIsHover(true); setClicked(false)}}
                    onMouseLeave={() => setIsHover(false)}>
                  <Tooltip id={menu.id}  />
                </div>
              </MuiTooltip>
              {userCtx.adminUser && (
                <>
                  <div>
                    <UpdateMenu
                      menu={menu}
                      updater={updater}
                    />
                  </div>
                  <div>
                    <DeleteMenu id={menu.id} visible={setVisible} deleter={deleter}/>
                  </div>
                </>
              )}
            </div>
          </div>
        </li>
      )}
    </MuiThemeProvider>
  );
}

export default Menu;
