import NewMenuForm from "../components/menus/NewMenuForm";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import UserContext from "../store/user-context";
import { NewMenuType } from "../@types/lunchs";

const serverURI = process.env.REACT_APP_API_ENDPOINT;

function RegisterMenuPage() {
  const userCtx = useContext(UserContext);

  const navigate = useNavigate();
  
  function addMenuHandler(menuData: NewMenuType) {
    fetch(`${serverURI}/api/menu`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(menuData),
      headers: {
        "Content-Type": "application/json",
        "X-CSRFTOKEN": userCtx.csrf,
      },
    }).then((response) => {
      if (response.ok) navigate("/", { replace: true });
      else userCtx.setLoggedIn?.(false);
    })
    .catch((err) => console.log(err));
  }

  return (
    <section>
      <h1>Add New Menu</h1>
      <NewMenuForm onAddMenu={addMenuHandler} />
    </section>
  );
}

export default RegisterMenuPage;
