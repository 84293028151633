import Row from '../ui/Row';
import MenuList from '../menus/MenuList';
import classes from './DayItem.module.css';
import UserContext from '../../store/user-context';
import { useContext, useState } from 'react';
import LastChoicesModal from './LastChoicesModal';
import { Button, createTheme, MuiThemeProvider } from '@material-ui/core';
import { DayItemMenuType, DayItemType, MenuType } from "../../@types/lunchs";

const serverURI = process.env.REACT_APP_API_ENDPOINT;

function DayItem({ dayOfTheWeek, week, menus, onUpdate }: DayItemType) {
  const userCtx = useContext(UserContext);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const [listOfMenus, setListOfMenus] = useState<DayItemMenuType[]>(menus);

  function publishButtonHandler() {
    fetch(`${serverURI}/api/slack/publish?date=${week}`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFTOKEN": userCtx.csrf,
      },
    })
      .then((response) => {
        if (!response.ok) userCtx.setLoggedIn(false);
      })
      .catch((err) => console.log(err));
  }

  const onDelete = (menuId: number) => {
    let newList: MenuType[] = JSON.parse(JSON.stringify(listOfMenus));
    newList = newList.filter((menu) => menu.id !== menuId);
    setListOfMenus(newList);
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#fcf3f6",
        contrastText: "black" //button text white instead of black
      },
    }
  });

  function withoutZeros(value: string) {
    // Remove leading zeros of two digit numbers
    // Used to match testing format - 1/1/2023 instead of 01/01/2023
    if (value[0] == '0') {
      value = value[1];
    }
    return value
  }

  function dayFormat() {
    // Change 'week' date format from YYYY-MM-DD to D/M/YYYY 
    var [year, month, dayOfMonth] = week.split("-");
    const day = `${withoutZeros(dayOfMonth)}/${withoutZeros(month)}/${year}`;
    return day
  }

  return (
    <>
      {listOfMenus.length > 0 && (
        <div>
          <h2>
            {dayOfTheWeek} {week ? dayFormat() : ""}:      
          </h2>
          <Row>
            <MenuList menus={menus} updater={onUpdate} deleter={onDelete}/>
            <div className={classes.btnContainer}>
              {userCtx.adminUser && (
                <MuiThemeProvider theme={theme}>
                  <Button onClick={publishButtonHandler} variant="contained" size="small" color="primary">
                    Publish
                  </Button>
                  <Button onClick={() => setOpenModal(true)} variant="contained" size="small" color="primary">
                    Set last subs
                  </Button>
                  <LastChoicesModal
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    week={week}
                    menus={menus}
                  />
                </MuiThemeProvider>
              )}
            </div>
          </Row>
        </div>
      )}
    </>
  );
}

export default DayItem;
