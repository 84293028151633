import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { LastChoicesModalInput } from "../../@types/lunchs";
import UserContext from "../../store/user-context";

const serverURI = process.env.REACT_APP_API_ENDPOINT;

function LastChoicesModal({ open, onClose, week, menus }: LastChoicesModalInput) {
  const [lastChoices, setLastChoices] = useState<Map<string, number>>(
    new Map<string, number>()
  );

  const userCtx = useContext(UserContext);

  function setLastChoicesButtonHandler() {
    fetch(`${serverURI}/api/menu/last_choices?date=${week}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFTOKEN": userCtx.csrf,
      },
      body: JSON.stringify(Object.fromEntries(lastChoices)),
    })
      .then((response) => {
        if (!response.ok) userCtx.setLoggedIn(false);
        else onClose();
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    let initializeChoices = new Map<string, number>();
    menus.map((menu) => initializeChoices.set(menu.id.toString(), 0));
    setLastChoices(initializeChoices);
  }, [menus]);

  if (!open) return null;

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" style={{textAlign: "center"}}>
        <DialogTitle id="form-dialog-title">Update menu</DialogTitle>
        <DialogContent style={{ display: "grid", alignItems: "center"}}>
          <DialogContentText>
            Please set the number of subs left:
          </DialogContentText>

          {menus.map((menu) => {
              return (
                <TextField
                  key={menu.id}
                  id={menu.id.toString()}
                  label={menu.description}
                  type="number"
                  value={lastChoices.has(menu.id.toString()) ? lastChoices.get(menu.id.toString()) : 0}
                  onChange={(e) => {
                    let currentLastChoices = new Map<string, number>(lastChoices);
                    currentLastChoices.set(
                      menu.id.toString(),
                      +e.target.value
                    );
                    setLastChoices(currentLastChoices);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                )
            })}
        </DialogContent>
        <DialogActions>
          <Button onClick={setLastChoicesButtonHandler} color="primary">
            Set choices
          </Button>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>)
}

export default LastChoicesModal;
