import { FormEvent, useState } from "react";
import { NewMenuFormType, NewMenuType } from "../../@types/lunchs";
import classes from './NewMenuForm.module.css';

function NewMenuForm({onAddMenu}: NewMenuFormType){

    const [date, setDate] = useState<string>((new Date()).toLocaleDateString());
    const [description, setDescription] = useState<string>('');
    const [isVegetarian, setIsVegetarian] = useState<boolean>(false);
    const [isVegan, setIsVegan] = useState<boolean>(false);
    const [isCeliac, setIsCeliac] = useState<boolean>(false);
    const [isSugar, setIsSugar] = useState<boolean>(false);
    const [isLactose, setIsLactose] = useState<boolean>(false);
    const [isPackaged, setIsPackaged] = useState<boolean>(false);

    
    function submitHandler(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
  
        const enteredDate = date;
        const enteredDescription = description;
        const enteredVegetarian = isVegetarian;
        const enteredVegan = isVegan;
        const enteredCeliac = isCeliac;
        const enteredSugar = isSugar;
        const enteredLactose = isLactose;
        const enteredPackaged = isPackaged;
        
        const menuData: NewMenuType = {
            date: enteredDate,
            description: enteredDescription,
            vegetarian: enteredVegetarian,
            vegan: enteredVegan,
            celiacFriendly: enteredCeliac,
            sugarFree: enteredSugar,
            lactoseFree: enteredLactose,
            packaged: enteredPackaged
        };
        
        onAddMenu(menuData);
    }
    
    return (
        <form className={classes.form} onSubmit={submitHandler}>
            <div className={classes.control}>
                <label htmlFor="date">Date</label>
                <input onChange={(e) => setDate(e.target.value)} value={date} type="date" required id="date" />
            </div>
            <div className={classes.control}>
                <label htmlFor="description">Menu Description</label>
                <input onChange={(e) => setDescription(e.target.value)} value={description} type="text" required id="description" />
            </div>
            <div className={classes.control}>
                <label htmlFor="vegetarian">
                    <div className={classes.labelDiv}>
                        Is it vegetarian?
                        <input onChange={(e) => setIsVegetarian(e.target.checked)} value={isVegetarian.toString()} type="checkbox" id="vegetarian" />
                    </div>
                </label>
            </div>
            <div className={classes.control}>
                <label htmlFor="vegan">
                    <div className={classes.labelDiv}>
                        Is it vegan?
                        <input onChange={(e) => setIsVegan(e.target.checked)} value={isVegan.toString()} type="checkbox" id="vegan" />
                    </div>
                </label>
            </div>
            <div className={classes.control}>
                <label htmlFor="celiacFriendly">
                    <div className={classes.labelDiv}>
                        Is it celiac friendly?
                        <input onChange={(e) => setIsCeliac(e.target.checked)} value={isCeliac.toString()} type="checkbox" id="celiacFriendly" />
                    </div>
                </label>
            </div>
            <div className={classes.control}>
                <label htmlFor="sugarFree">
                    <div className={classes.labelDiv}>
                        Is it sugar free?
                        <input onChange={(e) => setIsSugar(e.target.checked)} value={isSugar.toString()} type="checkbox" id="sugarFree" />
                    </div>
                </label>
            </div>
            <div className={classes.control}>
                <label htmlFor="lactoseFree">
                    <div className={classes.labelDiv}>
                        Is it lactose free?
                        <input onChange={(e) => setIsLactose(e.target.checked)} value={isLactose.toString()} type="checkbox" id="lactoseFree" />
                    </div>
                </label>
            </div>
            <div className={classes.control}>
                <label htmlFor="packaged">
                    <div className={classes.labelDiv}>
                        Is it packaged food?
                        <input onChange={(e) => setIsPackaged(e.target.checked)} value={isPackaged.toString()} type="checkbox" id="packaged" />
                    </div>
                </label>
            </div>
            <div className={classes.actions}>
                <button>Add Menu</button>
            </div>
        </form>
    )
}
    
export default NewMenuForm;