import { IconButton, Tooltip } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { useState } from "react";
import { UpdateMenuType } from "../../@types/lunchs";
import UpdateMenuModal from "./UpdateMenuModal";

function UpdateMenu({menu, updater}: UpdateMenuType) {
  const [openModal, setOpenModal] = useState<boolean>(false);

  return (
    <>
      <Tooltip title="Update menu" arrow>
        <IconButton aria-label="update" size="medium" onClick={() => setOpenModal(true)}>
          <Edit />
        </IconButton>
      </Tooltip>
      <UpdateMenuModal menu={menu} open={openModal} handleClose={() => setOpenModal(false)} updater={updater}/>
    </>
  );
}

export default UpdateMenu;
