import { useContext, useEffect, useState } from "react";
import DayItem from "../components/days/DayItem";
import { Link } from "react-router-dom";
import UserContext from "../store/user-context";
import { MenuType } from "../@types/lunchs";
import { Button } from "@material-ui/core";

const serverURI = process.env.REACT_APP_API_ENDPOINT;

function getDayOfWeek(dateAsString: string | undefined): string {
  
  if (!dateAsString) return "";

  let date = new Date(dateAsString);

  switch (
    date.getDay() + 1 // 0 is sunday
  ) {
    case 0:
      return "Sunday";
    case 1:
      return "Monday";
    case 2:
      return "Tuesday";
    case 3:
      return "Wednesday";
    case 4:
      return "Thursday";
    case 5:
      return "Friday";
    case 6:
      return "Saturday";
    default:
      return "";
  }
}

function getDatesOfWeek(dateAsString: string): Map<string,string>{
  let today = new Date(dateAsString);
  today = new Date(today.getTime() + (today.getTimezoneOffset() * 60000));
  // Monday
  let dayIt = new Date(today.setDate(today.getDate() - today.getDay() + 1));
  
  let result = new Map<string,string>();
  result.set("Monday", (new Date(dayIt)).toISOString().split('T')[0]);
  
  while (dayIt.setDate(dayIt.getDate() + 1) && dayIt.getDay() !== 6) {
    let formatted = (new Date(dayIt)).toISOString().split('T')[0]
    result.set(getDayOfWeek(formatted), formatted);
  }
  return result
}

function getDayFormat(dayOffset: number): string {
  let day = new Date();
  const offset = new Date().getTimezoneOffset();
  day = new Date(day.getTime() - offset * 60000);
  day.setDate(day.getDate() + dayOffset);
  return day.toISOString().split("T")[0];
}

function WeeklyChoicePage() {
  const [menus, setMenus] = useState<Map<string,MenuType[]>>(new Map<string,MenuType[]>());

  const [day, setDay] = useState<string>(getDayFormat(0));

  const [isToday, setIsToday] = useState<boolean>(true);

  const [weekDates, setWeekDates] = useState<Map<string,string>>(new Map<string,string>());

  const userCtx = useContext(UserContext);

  function clickHandler(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, offset: number): void {
    e.preventDefault();
    offset > 0 ? setIsToday(false) : setIsToday(true);
    setDay(getDayFormat(offset));
  }
  
  // Populate dates
  useEffect(() => setWeekDates(getDatesOfWeek(day)), [day]);

  // Populate menus
  useEffect(() => {
    fetch(`${serverURI}/api/menu?date=${day}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "X-CSRFTOKEN": userCtx.csrf,
      },
    })
      .then((response: Response) => {
        if (!response.ok) userCtx.setLoggedIn(false);
        return response.json();
      })
      .then((data: [MenuType]) => {
        let dictOfMenus = new Map<string,MenuType[]>();
        dictOfMenus.set("Monday", []);
        dictOfMenus.set("Tuesday", []);
        dictOfMenus.set("Wednesday", []);
        dictOfMenus.set("Thursday", []);
        dictOfMenus.set("Friday", []);

        data.map((i: MenuType) => (dictOfMenus.get(getDayOfWeek(i.date))?.push(i)));

        setMenus(dictOfMenus);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [day, userCtx]);

  function onMenuUpdate(menu: MenuType) {
    let dictOfMenus = new Map<string,MenuType[]>(menus);

    let day = getDayOfWeek(menu.date);

    Array.from(dictOfMenus.keys()).forEach(key => {
      let newList = dictOfMenus.get(key)?.filter(m => m.id !== menu.id);
      if (newList) dictOfMenus.set(key, newList);
    });
    dictOfMenus.get(day)?.push({
      id: menu.id,
      date: menu.date,
      description: menu.description,
      vegetarian: menu.vegetarian,
      vegan: menu.vegan,
      celiacFriendly: menu.celiacFriendly,
      sugarFree: menu.sugarFree,
      lactoseFree: menu.lactoseFree,
      packaged: menu.packaged
    });

    setMenus(dictOfMenus);
  }

  async function publishAllButtonHandler() {
    for (const [_, menuType] of menus.entries()) {
      if (menuType.length > 0) {
        try {
          await fetch(`${serverURI}/api/slack/publish?date=${menuType[0].date}`, {
            method: "POST",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              "X-CSRFTOKEN": userCtx.csrf,
            },
          });
        } catch (err) {
          console.log(err);
        }
      }
    }
  }

  return (
    <div>
      <>
        {isToday && (
          <div>
            <h1>This week's menus</h1>
            <Link to="/" onClick={(e) => clickHandler(e, 7)}>
              Check out what's coming next week
            </Link>
          </div>
        )}

        {!isToday && (
          <div>
            <h1>Coming next week</h1>
            <Link to="/" onClick={(e) => clickHandler(e, 0)}>
              Go back to current week
            </Link>
          </div>
        )}

        {Array.from(menus.entries()).map((entry) => {
          const [key, value] = entry;

          return (value.length > 0) && 
            <DayItem key={key} dayOfTheWeek={key} week={weekDates.get(key) || ''} menus={value} onUpdate={onMenuUpdate}/>
        })}

        {
          menus.get('Monday')?.length === 0 &&
          menus.get('Tuesday')?.length === 0 &&
          menus.get('Wednesday')?.length === 0 &&
          menus.get('Thursday')?.length === 0 &&
          menus.get('Friday')?.length === 0 &&
          
          <h1>TBA</h1>  
        }

        {userCtx.adminUser && (
          <Button 
            onClick={publishAllButtonHandler} 
            variant="contained" 
            size="small" 
            color="primary"
            style={{ marginTop: 30, width: 300}}>  
          Publish All Menus
          </Button>
        )}
        
      </>
    </div>
  );
}

export default WeeklyChoicePage;
